import axios from "../http-client/axios";
import { OPETH_URL } from "../urls/base-urls";
import mapperToDocumentRequest from "./document-mapper";

export async function documentPDFUpload(formData) {
  const mappedFormData = mapperToDocumentRequest(formData);
  let formDataInstance = new FormData();

  formDataInstance.append("file", mappedFormData.files[0]);
  formDataInstance.append("crm", mappedFormData.crm);
  formDataInstance.append("type", mappedFormData.fileType);
  formDataInstance.append("year", mappedFormData.year);
  if (mappedFormData.month)
  formDataInstance.append("month", mappedFormData.month);

  const resp = await axios({
    url: `${OPETH_URL}`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp?.data;
}

export async function sheetUpload(formData) {
  const mappedFormData = mapperToDocumentRequest(formData);
  let formDataInstance = new FormData();

  formDataInstance.append("file", mappedFormData.files[0]);
  formDataInstance.append("crm", mappedFormData.crm);
  formDataInstance.append("year", mappedFormData.year);
  formDataInstance.append("month", mappedFormData.month);

  const resp = await axios({
    url: `${OPETH_URL}/excel-support-fund`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp?.data;
}
